import {
  type ErrorResponse,
  isRouteErrorResponse,
  useNavigate,
  useParams,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import type { PropsWithChildren, ReactNode } from "react";

import notFoundImg from "~/assets/images/pages/404/404.png";
import { Button } from "~/components/ui/button";
import { PageContainer } from "~/components/ui/layout/page-container";
import { Wrapper } from "~/components/ui/layout/wrapper";
import { getErrorMessage } from "~/utils/errors";

type StatusHandler = (info: {
  error: ErrorResponse;
  params: Record<string, string | undefined>;
}) => JSX.Element | null;

export function ErrorComponent({
  statusHandlers,
  defaultStatusHandler = DefaultStatusHandlerComponent,
  unexpectedErrorHandler = DefaultUnexpectedErrorHandler,
}: {
  statusHandlers?: Record<number, StatusHandler>;
  defaultStatusHandler?: StatusHandler;
  unexpectedErrorHandler?: (error: unknown) => JSX.Element | null;
}) {
  const error = useRouteError();
  const params = useParams();

  captureRemixErrorBoundaryError(error);

  if (typeof document !== "undefined") {
    console.error(error);
  }

  return (
    <>
      <PageContainer>
        {isRouteErrorResponse(error)
          ? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
              error,
              params,
            })
          : unexpectedErrorHandler(error)}
      </PageContainer>
    </>
  );
}

function DefaultStatusHandlerComponent({ error }: { error: ErrorResponse }) {
  return (
    <ErrorWrapper>
      <p>{error.data}</p>
      <BackButtons />
    </ErrorWrapper>
  );
}

function DefaultUnexpectedErrorHandler(error: unknown) {
  return (
    <ErrorWrapper>
      <p>{getErrorMessage(error)}</p>
      <BackButtons />
      {error instanceof Error && error.stack && error.stack.length > 0 ? (
        <pre className="mt-8 w-full overflow-auto rounded-lg border border-grey-100 p-4 text-start text-sm text-grey-600 shadow-md">
          {error.stack}
        </pre>
      ) : null}
    </ErrorWrapper>
  );
}

function ErrorWrapper({ children }: { children: ReactNode }) {
  return (
    <Wrapper className="text-center">
      <div className="text-xl font-semibold text-danger-700">Une erreur est survenue</div>
      <div className="mt-6 text-grey-700">{children}</div>
    </Wrapper>
  );
}

function BackButtons() {
  const navigate = useNavigate();
  return (
    <div className="mt-6 flex items-center justify-center gap-4">
      <Button onClick={() => navigate(0)}>Recharger la page</Button>
      <Button onClick={() => navigate(-1)} variant="outline">
        Page précédente
      </Button>
    </div>
  );
}

export function NotFoundPage({ children }: PropsWithChildren) {
  const navigate = useNavigate();
  return (
    <Wrapper className="text-center">
      <h1 className="text-xl font-semibold text-danger-700">Cette page n&apos;existe pas.</h1>
      <p className="mt-6 text-grey-700">{children}</p>
      <img src={notFoundImg} alt="404" className="mx-auto mt-8 w-full max-w-lg" />
      <div className="mt-8 flex items-center justify-center gap-4">
        <Button onClick={() => navigate(-1)}>Page précédente</Button>
        <Button as="link" to="/" variant="outline">
          Retour à l&apos;accueil
        </Button>
      </div>
    </Wrapper>
  );
}
